// Alerts

@mixin alert-variant($background, $border, $text-color) {
	background-color: $background !important;
	border-color: $border;
	color: $text-color !important;

	hr {
		border-top-color: darken($border, 5%);
	}
	.alert-link {
		color: darken($text-color, 10%);
	}
}
