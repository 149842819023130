.thumb-wrapper {
	position: relative;

	.svg-inline--fa {
		position: absolute;
		bottom: 10px;
		left: 10px;
		opacity: 0.75;
	}

	.img-thumbnail{
		border:none;
		border-radius: 0;
		padding: 0;
	}
}

.img-fluid.js-before, .img-fluid.js-after {
	max-height: 70vh;
}