.alert {
	border-radius: $alert-border-radius;
	border-width: $alert-border-width;

	//$background, $border, $text-color
	&.alert-info {
		@include alert-variant($container-bg-medium, $brand-info, $text-color-primary);
	}
	&.alert-success {
		@include alert-variant($container-bg-medium, $brand-success, $alert-success-color);
	}
	&.alert-danger {
		@include alert-variant($container-bg-medium, $brand-danger, $alert-danger-color);
	}

	margin: 1rem 0;
	padding: 0.5rem 1rem;

	svg.svg-inline--fa {
		margin-top: .25rem;
	}
}