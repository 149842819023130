.btn {
	cursor: pointer;
	font-family: $font;
	font-weight: lighter;
	text-transform: uppercase;
	
	&:disabled {
		cursor:not-allowed;
	}
	
	&.btn-noIcon {
	}
	
	&.btn-danger {
    	color: $btn-danger-bg;
    	background-color: $white;
    }
	&.btn-outline-info {
    	color: $text-color-hover;
    	background-color: $white;
    }

	&.btn-primary.dropdown-toggle-split:hover:after {
		border-color: $btn-primary-hover-color;
	}
}

/*
 * Padding after icons in front of text
 */
.btn > .svg-inline--fa {
	margin-left: 0.3rem;
	margin-right: 0.3rem;
}
 
.btn-primary {
	//btn-style(text, bg, border, hover-bg, hover-border, hover-color)
	@include btn-style($btn-primary-color, $btn-primary-bg, $btn-primary-border, $btn-primary-hover-bg, $btn-primary-hover-border, $btn-primary-hover-color);
}
.btn-secondary {
	@include btn-style($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border, $btn-secondary-hover-bg, $btn-secondary-hover-border, $btn-secondary-hover-color);
}

.btn-info {
	@include btn-style($btn-info-color, $btn-info-bg, $btn-info-border, $btn-info-hover-bg, $btn-info-hover-border, $btn-info-hover-color);
}

.btn-group {

	button + button {
		border-left: $btn-border-width solid $border-color;
	}
		//radio buttons
	> .btn.active {
		border-color: $btn-primary-border;
		border-width: $btn-border-width;
		color: $text-color-primary;
	}
		// Split button dropdowns
	&.show {
		> .btn-primary.dropdown-toggle {
			background-color: $btn-primary-hover-bg;
			border-color: $btn-primary-hover-border;
		}
	}
}