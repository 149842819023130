$comment-bg: $grey-dark;

.comment-box {

	.time {
		font-size: .8rem;
		margin: 0 1rem;
	}
	.body {
		background-color: $comment-bg;
		margin-bottom: 1.5rem;
		padding: 1rem;
	}

	.author {
		float: right;
		margin-top: 1.5rem;
		text-align: right;

	}
} //.comment-box

// speech bubble arrow left
.comment-box .body {
	position: relative;

	&:after {
		content:'';
		position: absolute;
		bottom: 0;
		left: 0;
		top: 20%;
		width: 0;
		height: 0;
		border: $font-size/2 solid transparent;
		border-right-color: $comment-bg;
		margin-left: -$font-size;
	}
}

//speech-bubble arrow right
.comment-box:not(.own) .body:after {
	border-left-color: $comment-bg;
	border-right-color: transparent;
	left: unset;
	right: 0;
	margin-right: -$font-size;
}

// non-author
.comment-box:not(.own) {

	.time {
		text-align: right;
	}

	.author {
		float: none;
		margin-right: 0;
		text-align: left;
	}
}

// customer/team comment boxes (admin uses container-fluid)
.container {
	.comment-box {
		.body {
			margin-right: 3rem;
		}
	}
	.comment-box:not(.own) {
		.body {
			margin-left: 3rem;
			margin-right: 0;
		}
	}
}


//.comment-box,
//.table.datatable,
//table.comments {
//	.comment-meta span,
//	.comment-meta a {
//		font-size: 0.8rem;
//		//span + span {
//			padding-right: 0.5rem;
//		//}
//	} //.comment-meta
//
//	.author {
//		font-weight: bold;
//	}
//
//	ul.attachments {
//		list-style: none;
//		margin-bottom: 0;
//		padding-left: 0;
//
//		li {
//			display: inline;
//			padding-right: 1rem;
//		}
//	} // ul.attachments
//}

table.comments {
	width: 100%;
	td {
		vertical-align: top;
		padding: 0.5rem 15px;
		
		.comment-meta {
		font-size: 0.8rem;
		span + span {
			padding-left: 1rem;
			}
		}
		
		.author {
			font-weight: bold;
		}
		/*
		.comment-meta {
			font-size: 0.8rem;
			span + span {
				padding-left: 1rem;
			}
		}
		*/
	} //td
	/*
	 * striped tables
	 */
	tr:nth-child(even) {
		background-color: $container-bg-dark;
	}
	tr:nth-child(odd) {
		background-color: $container-bg-medium;
	}

} //table.comments

	//Dashboard
table.js-comments {
	.js-toggle-read {
		span.status {
			display: none;
		}
	}
} //table.comments

/*
 * Comment status
 */
a.unread {
	color: $blue-light;
	&:hover {
		color: $blue-medium;
	}
}

a.read {
	color: $grey-light;
	&:hover, &:active:hover, &:focus:hover {
		color: $grey-medium;
	}
	&:active, &:focus {
		color: $grey-light;
	}
}