input, textarea {
	background-color: $input-bg;
	border: $input-border-width solid $input-border-color;
	border-radius: $input-border-radius;
	color: $text-color-primary;
}

.input-group {
	.input-group-addon {
		background-color: transparent;
		border: none;
		color: $text-color-primary;
		margin-top: .25rem;
		padding-top: 0;
		padding-bottom: 0;
		padding-left: 0;
	}
	.form-control:not(:first-child) {
		@include border-left-radius($input-border-radius);
	}
	
	&.has-danger {
		color: $brand-danger;
	}
}

textarea {
	padding: 0.5rem;
	min-height: 4rem;
	width: 100%;
	
}

.form-control {
	background-color: $input-bg;
	border: 1px solid $input-border-color;
	border-radius: $input-border-radius;
	color: $text-color-primary;

	&:focus{
		background-color: $input-bg-focus;
		color: $text-color-primary;
	}
}

::placeholder {
	padding-left: .5rem;
	color: $text-color-primary;
	opacity: 0.8;
}

input:required,
select:required,
textarea:required
 {
	//background-color: lighten($blue-medium, 65%);
	//border: 1px solid $blue-medium;
}

/*
 * Hide chrome datepicker
 */
input::-webkit-calendar-picker-indicator{
    display: none;
}
input[type="date"]::-webkit-input-placeholder{ 
    visibility: hidden !important;
}
input[type=date]::-webkit-inner-spin-button, input[type=date]::-webkit-calendar-picker-indicator {
   display: none;
}

	//default hide approve buttons
.approve {
	display: none;
	padding-bottom: 1rem;
}

label {
	width: 100%;
	//text-align: right;
	vertical-align: sub;
	
	&.required {
		&:after {
	        content: '*';
	        padding-left: 3px;
		}
	}
}

.custom-control-label{
	color: $text-color-primary;
}

.custom-select {
	background: $custom-select-background;
	color: $text-color-primary;
	&:focus {
		color: inherit;
		background-color: $grey-medium;
	}

}

/*
BS4 custom checkbox (square)
 */
.custom-control.custom-checkbox {
	$control-label-offset: 0rem;	//0.6
	height: auto;
	width: auto;
	input:checked ~ .custom-control-label:before{
		background-color: $blue-light;
	}
	input:checked ~ .custom-control-label:after{
		width: 1.7rem;
		height: 1.7rem;
		top: 0px;
		left: -27px;
	}

	.custom-control-label{
		margin-left: $control-label-offset;
		padding-left: 0.5rem;
		&:before{
			background-color: $grey-lighter;
			border-radius: 0;
			border: 2px solid $blue-light;
			margin-top: -2px;
			margin-left: -$control-label-offset;
			width: 1.4rem;
			height: 1.4rem;
		}

		&:after{
			width: 1.5rem;
			height: 1.5rem;
		}
	}
}

.input-group {

	&.has-danger .form-control-feedback {
		color: $alert-danger-color;
	}
	.form-control-feedback {
		color: $text-color-primary;
	}
}




/**
Parsley validation
 */

input.parsley-success, input.parsley-success:focus,
select.parsley-success, select.parsley-success:focus,
textarea.parsley-success, textarea.parsley-success:focus {
	//color: $form-feedback-valid-color;
	//background-color: rgba($form-feedback-valid-color, .1);
	border: 1px solid $form-feedback-valid-color;
}

input.parsley-error,
input.parsley-error:focus,
select.parsley-error,
select.parsley-error:focus,
textarea.parsley-error,
textarea.parsley-error:focus {
	//color: $form-feedback-invalid-color;
	//background-color: rgba($form-feedback-invalid-color, .1);
	border: 1px solid $form-feedback-invalid-color;
}

.parsley-errors-list {
	color: $form-feedback-invalid-color;
	margin: 0.5rem 0;
	padding: 0;
	list-style-type: none;
	font-size: 0.9rem;
	line-height: 0.9rem;
	opacity: 0;

	transition: all $transD ease-in;

	&.filled {
		opacity: 1;
	}
}


