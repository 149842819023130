$black: #000;
$grey-dark: #212121;
$grey-medium: #303030;
$grey-light: #595959;
$grey-lighter: lighten($grey-medium, 25%);
$white: #FFF;
$blue-medium: #04e4dd;
$blue-light: lighten($blue-medium, 15%);
$blue-dark: darken($blue-medium, 15%);
 
$red-light: #ebcccc;
$red: #fd4b4b;
$green-light: #d0e9c6;
$green: $blue-medium;
$yellow-light: #faf2cc;
$yellow: #8a6d3b;
$orange: #f0ad4e;

$primary: $blue-medium;