nav.navbar.navbar-dark {
	background-color: $navbar-bg;
	height: $navbar-height;
	padding-top: 0;
	padding-bottom: 0;
	a.nav-link {
		color: $text-color-primary;
		font-weight: lighter;
		
		&:focus, &:hover {
			color: $text-color-hover;
		}
	}
		
	.navbar-brand {
		@include media-breakpoint-down('lg') {
			margin-right: 3rem;
			img {
				width: 22px;
			}
		}
		@include media-breakpoint-up('lg') {
			img {
				width: 100px;
			}
		}
	}

	.nav-link.active {
		font-weight: bold;
	}

	//btn-group
	.btn-group{
		> a{
			padding-right: 0.25rem;

		}
		> a, button{
			color: $text-color-primary;
			font-size: inherit;
			text-transform: none;
			padding-bottom: 0;
		}
		button.dropdown-toggle {
			padding-left: 0;
			background-color: transparent;
		}
	}

		//dropdown button
	.nav-item .dropdown {
		
		.btn{
			background-color: transparent;
			color: $text-color-primary;
			text-transform: none;

			//&:after {
			//	content:'';
			//		//bs4 dropdown overwrites
			//	width: initial;
			//	height: initial;
			//	margin-left: initial;
			//	vertical-align: initial;
			//	border:0;
			//}
			&:hover {
				color: $text-color-hover;
				
			}
		} //.btn
	}//.nav-item .dropdown
}

.stickymenu {
    background-color: $blue-dark;
    margin-bottom: 1rem;
    margin-top: -1rem;
    padding: 0.5rem;
    position: sticky;
	top:0;
    z-index: 1000;
    a {
    	color: $text-color-primary;
    	font-weight: lighter;
    	text-transform: uppercase;
    	 &:after {
	    	padding:0 0.5rem;
    	 	content: '|';
    	 }
    }
}

/*
 * Tablist
 */
.nav[role=tablist] {
	.nav-link.active {
		color: $text-color-hover;
	}
}