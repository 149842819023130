.card {
	border: none;
	border-radius: 0;

	.card-body {
		background-color: $container-bg-medium;
	}
} //.card

/*
 * Block
 */
.card {
	margin-bottom: 1rem;
	.card-body {
		color: $text-color-primary;
		> .svg-inline--fa {
			margin: 0.5rem;
		}
		
		.card-title {
			display: block;
			margin-bottom: 1.5rem;
			padding-right: 0;
		}
		
		.card-text {
			.btn {
				margin: 1em 0;
			}
		}
	} //.card-bod
}

.card-lg {
	.card-text {
		.btn {
			margin: 2em 0;
		}
	}
}

/*
 * List Group
 */
.card {
	//background-color: transparent;
	
	.list-group {
		//border: $card-list-border-size solid $card-list-border-color;
		border:none;
		color: $text-color-primary;
		background-color: $container-bg-medium;
		
		.list-group-item {
			background-color: inherit;
			border-color: transparent;

			.svg-inline--fa {
				margin-right: 0.5rem;
			}
		}
	} //.list-group
} //.card