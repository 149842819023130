.comment-box:hover {
	.overlay-icon {
		opacity: 1;
	}
}
.overlay-icon {
	position: absolute;
	top: 0;
	right: 0;
	opacity: 0;
	a > .svg-inline--fa {
		margin: 1rem;
	}
}