.help-slideout {
	display: inline-block;
	float: right;
	height: 0;
	position: fixed;
	top: 0;
	right: 0;
	z-index:2000;
	
	.help-slideout-opener {
		background-color: $btn-secondary-bg;
		color: $btn-secondary-color;
		cursor: pointer;
		margin-left: 305px;
		//margin-top: $navbar-height;
		padding: 12px;
		text-transform: uppercase;
		//transform: rotate(-90deg);
		
		&:hover {
			text-decoration: underline;
			color: $btn-secondary-hover-color;
			background-color: $btn-secondary-hover-bg;
		}
	} //.-opener
	
	.help-slideout-content {
		background-color: $container-bg-dark;
		max-width: 350px;
		max-height: 85vh;
		overflow-y:auto;
		padding: 1rem;
		//position: sticky;
		right: 0;
		transform: translateX(100%);
		transition: transform 0.6s ease-out;
		
		&.isOpen {
			transform: translateX(0);
		}
	}
}

.help-slideout .help-slideout-content {
	ul {
		margin-top: 0.5rem;
		&.fa-ul {
			margin-left: 1rem;
		}
		li {
			margin-bottom: 0.5rem;
		}
	}
}