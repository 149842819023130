.ribbon {
	transform: rotate(-45deg);
	border: 30px solid transparent;
	border-bottom: 30px solid $blue-medium;
	position: absolute;
	top: 20px;
	left: -60px;
	width: 220px;
	color: $text-color-secondary;
	
	.txt {
		position: absolute;
		top: 3px;
		left: 20px;
	}
}
