.heading {
	padding-bottom: 1rem;

	.col {
		margin-left: 15px;
		margin-right: 15px;
		padding: 0;
		padding-bottom: 1rem;
		border-bottom: $border-width solid $border-color;
	}

	h1,h2 {
		display: inline-block;
		padding-right: 1rem;
	}

	.svg-inline--fa {
		margin-right: 0.5rem;
	}
} //.heading