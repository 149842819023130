.row.error {
	margin-top: 10rem;
	
	a {
		color: $text-color-hover;
	}
	.error-warning {
		color: $badge-warning-bg;
	}
	.error-danger {
		color: $badge-danger-bg;
	}
}