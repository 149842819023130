/**
	Bootstrap sidenav
	@url https://bootstrapious.com/p/bootstrap-sidebar#sidebar-4
 */

body.active #app {
	padding-left: $navbar-width;
}

#sidenav {
	background-color: $navbar-bg;
	height: 100%;
	min-width: $navbar-height;
	max-width: $navbar-height;

	padding-top: $navbar-height;
	transition: all $transDshort;

	.sidenav-header {
		background-color: $container-bg-dark;
		text-align: center;
		margin-bottom: 1rem;
		padding: 5px 0 4px;

		button {
			color: $text-color-secondary;

			&:focus, .focus {
				box-shadow: none;
			}

			&:hover {
				color: $text-color-hover;
			}

			.svg-inline--fa {
				display: block;

				&.active {
					display: none;
				}
			}
		}
	}

	.nav-item{
		.nav-link {
			text-align: center;

			&.active{
				color: $text-color-hover;
			}
			span {
				display: none;
				padding-left: 1rem;
			}
		}
	}
}

#sidenav.active {
	min-width: $navbar-width;
	max-width: $navbar-width;

	.sidenav-header button{
		text-align: left;

		.svg-inline--fa {
			display: none;

			&.active {
				display: block;
			}
		}
	}

	.nav-item {
		.nav-link{
			text-align: left;
			span {
				display: inline-block;
			}
		}
	}
}