.avatar {
	$avatar-dimension: 50px;

	background-color: $container-bg-light;
	border-radius: $avatar-dimension;
	width: $avatar-dimension;
	height: $avatar-dimension;
	text-align: center;
	line-height: $avatar-dimension;

	&.avatar-colored {
		background-color: $container-bg-colored;

		a:hover & {
			color: $text-color-secondary;
		}
	}

	&.avatar-sm {
		$avatar-sm: 0.7;

		width: $avatar-dimension * $avatar-sm;
		height: $avatar-dimension * $avatar-sm;
		line-height: $avatar-dimension * $avatar-sm;
		font-size: 1.1em * $avatar-sm;
	}
}