.badge {
	border-radius: $alert-border-radius;
	font-size: 90%;
	font-weight: normal;
	
	&.badge-default {
		background-color: $badge-default-bg;
		color: $badge-default-color;
	}
	&.badge-primary {
		background-color: $badge-primary-bg;
	}
}