@keyframes bg-animation {
	0% {
	background-color: #355a7e;
	}
	25% {
	background-color: #206462;
	}
	50% {
		background-color: #03b1ac;
	}
	75% {
		background-color: #1563b1;
	}
	100% {
		background-color: #355a7e;
	}
} //include keyframe

html, body {
	//height: 100%;
}

body {
	background-color: $container-bg-light;

	&.bg-img {
		background-image: url('/img/bg/bg-top-left.png'), url('/img/bg/bg-bottom-right.png');
		background-position: left top, right bottom;
		background-repeat: no-repeat, no-repeat;
	}

	&.bg-animated {
		animation: bg-animation 20s infinite;
	}
}

#app {
	padding-left: $navbar-height;
	width: 100%;
	min-height: 100vh;
	transition: all 0.3s;
}

.bg-colored {
	background-color: $blue-medium;
}
.bg-dark {
	background-color: $container-bg-dark!important;
}
.bg-medium {
	background-color: $container-bg-medium!important;
}
.bg-light {
	background-color: $container-bg-light!important;
}

.section {
	padding: 4vh 0 0;
	
	&.section-dark {
		background-color: $container-bg-dark;
	}
	&.section-medium {
		background-color: $container-bg-medium;
	}
}

/*
 *  Slim Header section
 */
.section.section-slim {
	padding: 1vh 0;
	 h1, h2 {
	 	display: inline;
	 	padding-right: 0.5rem;
	 	line-height: 2.5rem;
	 }
}

.section.section-wide {
	padding-top: 4vh;
	padding-bottom: 4vh;
}
	 .section.users {
	 	.btn-sm {
	 		margin: 1rem 0;
	 	}
	 }

.border-dashed {
	border: $container-border-size dashed $container-border-primary-color !important;
}

/*
 * Jump above anchor
 */
.anchor {
	display: block;
	position: relative;
	top: -2rem; /*same height as header*/
	visibility: hidden;
}

.back-to-top {
    cursor: pointer;
    position: fixed;
    bottom: 50px;
    right: 15px;
    display:none;
    z-index: 900;
}