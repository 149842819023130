.page-item {

	.page-link {
		&:focus {
			//$pagination-focus-box-shadow
		}
		.svg-inline--fa {
			margin: .125rem 0;
		}
	}

	&:first-child {
		.page-link {

		}
	}
	&:last-child {
		.page-link {


		}
	}

	&.active .page-link {
		//color: $pagination-active-color;
		//background-color: $component-active-bg;
		//border-color: $pagination-active-border-color;
	}

	&.disabled .page-link {
		//color: $pagination-disabled-color;
		background-color: $grey-medium;
		//border-color: $pagination-disabled-border-color;
	}
}