table.datatable, table.datatableFixed {
	border-collapse: collapse!important;
	color: $text-color-primary;

	caption{
		caption-side: top;
	}
	
	th, td {
		border-top: 1px solid $table-border-color;
		padding: 0.5rem;
		vertical-align: top;
		
		textarea {
			padding: 0.25rem;
		}
	}

	td[colspan] {
		border-bottom: 2px solid $table-border-color;
	}
	
	thead {
		
		.sorting, .sorting_asc, .sorting_desc {
			&:before, &:after {
				//opacity: 0.5;
				top: 0.3rem;
			}
		}
		th {
			//background-color: $container-bg-medium;
			border: none;
		}
	} //thead

	a > .svg-inline--fa {
		margin-left: 0.5rem;
	}
	//fa-stacked icon sizing
	a > span {
		padding-left: 0.25rem;
		font-size: 0.8rem;
		margin-bottom: .75rem;
	}
	.fa-layers-counter {
		color: $text-color-secondary;
	}

	img {
		margin-right: 1rem;
		
		&.img-thumbnail {
			max-height: 8rem;
		}
	}
	.svg-inline--fa.img-thumbnail {
		//color: $text-color-secondary;
		background-color: transparent;
		border:none;
	}
	
	&.table-noOuterBorder {
		border: none;
	}
	
	//stripes
	/*
	&.table-striped tbody tr:nth-of-type(odd) {
		background-color: $container-bg-dark;
	}
	&.table-striped tbody tr:nth-of-type(even) {
		background-color: $container-bg-medium;
	}
	*/
} //table.datatable

.dataTables_processing {
	opacity: 0.7;
	border-radius: 0.5em;
	color: #212529;
}

.dataTables_wrapper.container-fluid {
	padding-left: 0;
	padding-right: 0;
	margin-bottom: 1rem;
}

// dataTables selected checkbox highlighting
table.dataTable tbody {
	> tr.selected {
		background-color: $blue-dark;
		
		a {
			color: $text-color-primary;
			&:hover, &:focus, &:active {
				color: $text-color-hover;
			}
		}
	} //tr.selected

	th.select-checkbox, td.select-checkbox {
	 	padding-left: 1.5rem;
		 &:before{
			 border-color: $text-color-primary;
			 border-radius: 1px;
		 }
	 }
}

//table.dataTable tbody td.select-checkbox:before,
//table.dataTable tbody th.select-checkbox:before {

div.dataTables_wrapper {
	div.dataTables_filter {
		float: right;

		label {
			position: relative;

			.svg-inline--fa {
				position: absolute;
				top: $font-size/2;
				left: $font-size;
			}
		}
		input[type='search']{
			border-radius: $btn-border-radius;
			padding: 1rem;
			text-indent: 1rem;
		}
	}
		//style select buttons as primary buttons
	.dt-buttons {
		float: left;
		margin-bottom: 0.5rem;
		padding-right: 1rem;

		&.btn-group {
			// Prevent double borders when buttons are next to each other
			> .btn:not(:first-child),
			> .btn-group:not(:first-child) {
				@include border-left-radius(0);
			}
		}

		.btn {
			padding: .25rem .5rem;
		}

		.btn-secondary {
			@include btn-style($btn-primary-color, $btn-primary-bg, $btn-primary-border, $btn-primary-hover-bg, $btn-primary-hover-border, $btn-primary-hover-color);
		}
	}
	div.dataTables_info {
		float: left;
		padding-top: 0.5rem;
	}
} //div.dataTables_wrapper


table.table-slim {
	th,td {
		padding: 0;
	}	
}

.order-list .nr-cl input{
	width: 100px;
}