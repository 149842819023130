.dz-previews {
	.progress {
		height: auto;
	    .progress-bar {
	        height: $container-border-size;
	        background-color: $blue-medium;
	    }
	}
    
    p {
        margin-bottom: 0.5rem;
    }
    
		//success icon on finished upload
	span.upload-success {
		display:none;
		color: $alert-success-color;
	}
	
	.dz-success span.upload-success {
		display:inline;
	}
	
		//hide delete buttons during upload
	.dz-processing {
		button.delete {
			display: none;
		}
		&.dz-complete {
			button.delete {
				display: inline;
			}
			.progress, .progress-bar {
				background-color: transparent;
			}
		}
	} //.dz-processing
} //.dz-previews