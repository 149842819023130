@mixin datepicker-button-variant($color, $background, $border) {
	color: $color;
	background-color: $background;
	background-image: inherit;
	border-color: $border;

	&:focus,
	&:active,
	&.active {
		color: $color;
		background-color: $background;//darken($background, 8%);
		border-color: darken($border, 2%);
	}

	&:hover{
		color: $color;
		background-color: darken($background, 2%);
		border-color: darken($border, 2%);
	}
}

.datepicker {
	
	thead {
		th {
			border-radius: 0px;
		}
	} //thead
	
		// picked day/month/year background color
	table tr td,
	table tr td span {
		border-radius: 0px;
		&.active.day,
	    &.active:hover,
	    &.active.disabled,
	    &.active.disabled:hover,
	    &.month.focused,
	    &.year.active, &.year.focused,
	    &.century.active, &.century.focused,
	    &.decade.active, &.decade.focused {
	      	@include datepicker-button-variant($text-color-secondary, $blue-medium, $blue-medium);
	    }
	    /*
	    &.today,
	    &.today:hover,
	    &.today.disabled,
	    &.today.disabled:hover {
	      	@include datepicker-button-variant($text-color, $grey-mediumDark, $grey-mediumDark);
	    }
	    */
	} // ./ table tr td
	
} // ./ .datepicker