.modal {
	.modal-content {
		background-color: $container-bg-medium;

		.modal-header {
			//border-bottom: none;
			border-bottom-color: $border-color;
			padding: 15px 30px;

			.close {
				color: $text-color-primary;
			}
		}

		.modal-footer {
			border-top-color: $border-color;
		}
	}
	.modal-lg {
		max-width: 90vw;
	}
}