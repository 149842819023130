
//== Breakpoints
//
//## 

// include-media plugging into bootstrap 4's grid breakpoints map
$breakpoints: $grid-breakpoints;


//== Fonts
//
//## Google Fonts

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');
$font: 'Roboto', sans-serif;


// Font Awesome
//$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";

// Components
//
// Define common padding and border radius sizes and more.

$border-color: $grey-lighter;
$border-color-medium: $grey-medium;

$font-size: 16px;
$font-size-h123-mobile: 30px;
$font-size-h123: 36px;
$font-size-h456-mobile: 20px;
$font-size-h456: 24px;
$font-size-h78-mobile: 16px;
$font-size-h78: 18px;
$line-height: 1.4;

$text-color-primary: $white;
$text-color-secondary: $grey-lighter;
$text-color-hover: $blue-medium;

$component-active-color:      $text-color-hover;
$component-active-bg:         $grey-light;

// Reassign color vars to semantic color scheme
$brand-primary:         	  	$white;
$brand-secondary:         	  	$blue-medium;
$brand-success:            	  	$green;
$brand-info:               	 	$grey-lighter;
$brand-warning:           	 	$orange;
$brand-danger: 					$red;
//$brand-inverse:         	    $gray-dark;


//== Container
//
//## div-container settings
$container-border-size: 3px;
$container-border-primary-color: $grey-lighter;
$container-border-secondary-color: $white;
$container-border-radius: 0px;
$container-bg-light: $grey-light;
$container-bg-medium: $grey-medium;
$container-bg-dark: $grey-dark;
$container-bg-colored: $blue-medium;


//== Navigation
//
//## navbar settings
$navbar-height: 48px;
$navbar-width: 200px;
$navbar-bg: $container-bg-medium;

// Progress bars

$progress-bg:					$grey-light;


// Alerts
$alert-border-radius:  		    2px;
$alert-border-width:         	2px;
$alert-success-color: 			$brand-success;
$alert-danger-color: 			$red;

// Badge
$badge-default-bg:  		    $grey-light;
$badge-default-color:  		    $text-color-primary;

$badge-primary-bg:  		    $blue-medium;
$badge-primary-bg-hover:	    $blue-dark;

$badge-warning-bg:  		    $orange;

$badge-danger-bg:  		    	$brand-danger;


// Buttons
$btn-border-radius:  		    100px;
$btn-border-width:         	    2px;

$btn-primary-color:        		$brand-primary;
$btn-primary-bg:              	$grey-dark;
$btn-primary-border:        	$grey-dark;
$btn-primary-hover-bg: 			$blue-medium;
$btn-primary-hover-border:		$blue-medium;
$btn-primary-hover-color: 		$text-color-secondary;

$btn-secondary-color:           $text-color-secondary;
$btn-secondary-bg:              $brand-secondary;
$btn-secondary-border:          $brand-secondary;
$btn-secondary-hover-bg:        $blue-dark;
$btn-secondary-hover-border:    $blue-dark;
$btn-secondary-hover-color:     $text-color-primary;

$btn-info-color: 				$brand-info;
$btn-info-bg: 					$container-bg-dark;
$btn-info-border:				$blue-medium;
$btn-info-hover-bg:				$blue-medium;
$btn-info-hover-border:			$btn-info-border;
$btn-info-hover-color:			$text-color-secondary;

$btn-danger-color: 				$red;
$btn-danger-bg: 				transparent;
$btn-danger-border:				$btn-danger-color;
$btn-danger-hover-bg:			$red;
$btn-danger-hover-border:		$btn-danger-border;
$btn-danger-hover-color:		$white;


//== Cards
//
//## cards settings
$card-list-border-size: 1px;
$card-list-border-color: $border-color;
$card-list-border-radius: 0px;


//== Forms
//
//## input, textarea settings
$input-bg: $grey-dark;
$input-bg-focus: $grey-medium;
$input-border-color: $border-color;
$input-border-radius: 2px;
$input-border-width: 1px;
$custom-select-indicator-color: $text-color-primary;
$custom-select-indicator:		str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e"), "#", "%23");
$custom-select-background:		$custom-select-indicator no-repeat right $custom-select-padding-x center / $custom-select-bg-size; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

//== Tables
//
//## tables settings
$table-border-size: 1px;
$table-border-color: $grey-light;


//Transition duration & scale factor (on hover)
$transDshort: 0.25s;
$transD: 0.5s;
$scaleFactor: 1.05;