
.login{
	color: $text-color-primary;
	position: relative;
	a {
		color: $text-color-hover;
		&:hover {
		color: $text-color-hover;
		}
	}
}
.login-box-body {
	border: $container-border-size solid $container-border-secondary-color;
	padding: 2rem;
}

.login-logo {
	margin: 3rem;
}