.orders-gallery {
	.title h3 {
		margin-bottom: 0;
	}
	.meta {
		padding-bottom: 1rem;
	}
}

.gallery{
	margin: 0;

	.gallery-img {
		background-color: $container-bg-light;
		padding: 15px;
		border: $border-width solid $border-color-medium;
	}

	.img-name{
		margin-top: .5rem;
		@include linesToShow(1)
	}
}

.gallery {
	position: relative;

	.pagination {
		display: flex;
		justify-content: space-between;
		width: 100%;
		position: absolute;
		top: 40%;
	}
}