.accordion {
	.card {
		background-color: inherit;
		border: 0;
		color: $text-color-primary;
		
		.card-header {
			background-color: transparent;
			padding-left: 0;

			.svg-inline--fa {
				float: right;
				font-size: 1.5em;
				margin-top: -0.1em;
			}
		} //.card-header
		
		.card-body {
			color: $text-color-primary;
		} //.card-body
	} //.card
} //.accordion