.dropdown-menu {
	background-color: $container-bg-medium;
	border-radius:0;
	min-width: 0;
	padding: 0;

	.dropdown-item {
		//text-align: right;
		border-radius: 0;
		color: $text-color-primary;
		&:hover, &:active, &:focus {
			background-color: $grey-light;
			color: $text-color-hover;
		}
		//&:active, &:focus {
		//	background-color: $btn-primary-hover-bg;
		//	color: $text-color-primary;
		//}
	} //.dropdown-item
}//.dropdown-menu

.dropdown-toggle {
	&:after {
		border-top: 0 solid transparent;
		border-right: 0 solid transparent;
		border-bottom: 2px solid $text-color-primary;
		border-left: 2px solid $text-color-primary;
		transform: rotate(-45deg);
		padding: 3px;
	}

	&:hover:after {
		border-color:$text-color-hover;
	}
}

.v-select {
	.dropdown-toggle {
		&:after {
			border:none;
		}

		.open-indicator {
			&:before {
				border-color: $text-color-primary;
			}
		}
	}

	.dropdown-menu {
		background-color: $container-bg-medium!important;
		color: $text-color-primary;

		& >.highlight>a {
			background-color: $container-bg-colored!important;
			color: $text-color-secondary!important;
		}
	}
}