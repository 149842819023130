body, a {
  font-family: $font;
  font-weight: 300;
  font-size: $font-size;
  color: $text-color-primary;
}

a {
  &:hover, &:active, &.active, &:focus {
    color: $text-color-hover;
    text-decoration: none;

    .fa-inverse {
      color: $text-color-primary;
    }
  }
}

h1, .title-h1 {
  color: $text-color-hover;
  font-size: 30px;
  font-weight: bold;
}

h2, .title-h2 {
  font-size: 30px;
  font-weight: lighter;
  margin-bottom: 1.5rem;
}

h3, .title-h3 {
  font-size: 24px;
  font-weight: bold;
  margin: 2rem 0 1rem;
}

h5 {
  margin-top: 0.5rem;
}

.linesToShow1 {
  & td, & p, & a, & span {
    @include linesToShow(1);
  }
}

.linesToShow1Fixed {
  @include linesToShow(1);
}

hr {
  border-top-color: $border-color;
}

.profile {
  span {
    font-weight: 300;
  }

}

/*
  Lists
 */
ul.list-inline {
  li .svg-inline--fa {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  li + li {
    &:before {
      content: ' | '
    }
  }
}

.text-info{
  color: $text-color-hover!important;
}

/**
  Font awesome
 */
.fa-inverse {
  color: $text-color-hover;
}

.fa-layers-counter {
  background-color: $text-color-hover;
  right: -140%;
}

.svg-inline--fa.shadow {
  filter: drop-shadow(0 0 2px $grey-dark);
}