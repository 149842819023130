
.page-link {
	border:none;
	background-color: $container-bg-dark;
	color: $text-color-primary;
	
	&:active, &:focus, &:hover {
		color: $text-color-hover;
		background-color: $component-active-bg;
	}
}

.page-item.active .page-link {
	background-color: $container-bg-medium;
	color: $text-color-primary;
	&:hover {
		color: $text-color-hover;
		background-color: $component-active-bg;
	}
}

.page-item:last-child .page-link {
	@include border-right-radius(0);
	padding-top: .4rem;
	padding-bottom: .4rem;
}
.page-item:first-child .page-link {
	@include border-left-radius(0);
	padding-top: .4rem;
	padding-bottom: .4rem;
}