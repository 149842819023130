// 20-20 variable overrides
$twenty20-handle-color: $grey-medium;
$twenty20-handle-stroke: 2px;
$twenty20-handle-radius: 0;
$twenty20-overlay-label-bg: rgba($grey-medium, .75);
@import "~zurb-twentytwenty/scss/twentytwenty-no-compass";

.twentytwenty-controls {
	ul {
		list-style: none;
		padding-left: 0;
	}
}

.twentytwenty-horizontal .twentytwenty-handle {
	background-color: rgba($grey-medium, .75);
	border:none;
	margin-left: -30px;
	margin-top: -19px;
	width: 60px;

	span {
		border: solid $white;
		border-width: 0 2px 2px 0;
		display: inline-block;
		padding: 4px;
	}

	.twentytwenty-left-arrow {
		transform: rotate(135deg);
	}
	.twentytwenty-right-arrow {
		transform: rotate(-45deg);
	}
}